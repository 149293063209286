document.addEventListener('DOMContentLoaded', () => {
  const $header = document.querySelector('.header')
  const $splashPage = document.querySelector('.splash')
  const $navTrigger = document.querySelector('.nav__trigger')

  const checkSticky = () => {
    const t = document.documentElement.scrollTop
    const H = window.innerHeight

    const isHeaderSticky = t > (5 * H) / 7
    $header.classList.toggle('is-sticky', isHeaderSticky)
    $splashPage.classList.toggle('is-sticky', isHeaderSticky && t > H - $header.offsetHeight)
  }

  window.addEventListener('load', checkSticky)
  window.addEventListener('scroll', checkSticky)
  window.addEventListener('orientationchange', checkSticky)

  $navTrigger.addEventListener('click', event => {
    event.preventDefault()
    $navTrigger.classList.toggle('is-pressed')
    document.body.classList.toggle('is-menu-shown')
  })

  document.body.addEventListener(
    'click',
    event => {
      const $target = event.target

      if ($target.closest('.nav__trigger') !== $navTrigger) {
        $navTrigger.classList.remove('is-pressed')
        document.body.classList.remove('is-menu-shown')
      }

      const $scrollTo = $target.closest('.js-scroll-to')
      if ($scrollTo !== null) {
        const sectionID = $scrollTo.getAttribute('href')
        const $section = document.querySelector(sectionID)
        $section.scrollIntoView({ left: 0, block: 'start', behavior: 'smooth' })
        // window.scrollTo({ top: section.offsetTop, left: 0, behavior: 'smooth' })
        event.preventDefault()
        history.pushState(null, null, sectionID)
      }

      const $mailTo = $target.closest('.js-mail-to')
      if ($mailTo !== null) {
        window.open($mailTo.getAttribute('href').replace(/_/g, ''))
      }

      const $accHead = $target.closest('.accordeon__head')
      if ($accHead !== null) {
        $accHead.classList.toggle('is-expanded')
        const $accBody = $accHead.nextSibling
        $accBody.style.setProperty('max-height', $accBody.scrollHeight + 'px')
        if ($accHead.classList.contains('is-expanded')) {
          $accBody.addEventListener('transitionend', () => $accBody.style.removeProperty('max-height'), { once: true })
        } else {
          requestAnimationFrame(() => $accBody.style.removeProperty('max-height'))
        }
        event.preventDefault()
      }
    },
    true
  )

  // Check for WebP support, and if not then set a class 'no-webp' on <body>
  // new Promise((resolve, reject) => {
  //   const img = new Image()
  //   img.onload = img.onerror = event =>
  //     (event && event.type === 'load' ? img.width == 1 : false) ? resolve() : reject()
  //   img.src = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA='
  // }).then(
  //   () => {},
  //   () => {
  //     document.body.classList.add('no-webp')
  //   }
  // )
})
